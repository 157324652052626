<template>
    <b-modal
    v-model="active"
    id="modal-submit"
    cancel-variant="secondary"
    ok-title="Kategori Harga"
    cancel-title="Harga"
    centered
    title="Form Kategori Harga"
    >
    
        <b-form>
            <b-form-group>
            <label for="nama">Nama Kategori</label>
            <b-form-input v-model="form.nama" id="nama" type="text" placeholder="Ex: Grosir" />
            </b-form-group>
        </b-form>

        <template #modal-footer>
            <section class="d-flex justify-content-end align-items-center">
            <b-button size="sm" class="mr-1" variant="primary" @click="submit">
                Submit
            </b-button>
            <b-button size="sm" variant="danger" @click="resetForm">
                Batal
            </b-button>
            </section>
        </template>
    </b-modal>
</template>
<script>
    import {BModal, BForm, BFormInput, BFormGroup, BButton} from 'bootstrap-vue'
    export default {
        components: {
            BModal, BForm, BFormInput, BFormGroup, BButton
        },
        data: () => ({
            form: {
                nama: null
            }
        }),
        computed: {
            active() {
                return this.$store.state['kategori-harga'].showModal
            },
            id() {
                return this.$store.state['kategori-harga'].id
            }
        },
        methods: {
            resetForm() {
                this.form.nama = null
                this.id = null
            },
            submit() {
                if( !this.form.nama ) {
                    this.displayError({
                        message: 'Harap Lengkapi Nama Kategori!'
                    })
                    return false
                }
                
                const isEmptyId = this.$store.state['kategori-harga'].id == null
                if(!isEmptyId) {
                    this.form.id = this.id
                }

                this.$emit('onSubmit', [this.form])
            }
        }
    }
</script>